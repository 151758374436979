@font-face {
  font-family: 'Gotham-Light';
  src: url('./fonts/Gotham-Font/Gotham-Light.otf');
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url('./fonts/Gotham-Font/Gotham-Bold.otf');
}

@font-face {
  font-family: 'Gotham-Medium';
  src: url('./fonts/Gotham-Font/Gotham-Medium.otf');
}

@font-face {
  font-family: 'Optima';
  src: url('./fonts/Optima/OPTIMA.woff');
}

@font-face {
  font-family: 'Optima Medium';
  src: url('./fonts/Optima/Optimamedium.woff');
}

@font-face {
  font-family: 'Optima Bold';
  src: url('./fonts/Optima/OPTIMA_B.woff');
}

:root {

  /* Color */
  --primary: #000;
  --black: #000000;
  --white: #fff;
  --lightbg: #F5F8F9;

  /* Fonts */
  --font_1: 'Gotham-Light';
  --font_2: 'Gotham-Medium';
  --font_3: 'Gotham-Bold';

  --primary-base: "Optima";
  --primary-medium: "Optima Medium";
  --primary-bold: "Optima Bold";
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"] {
  font-size: 16px;
}

@media(max-width:1024px) {

  body,
  html {
    overflow-x: hidden;
  }
}



body {
  font-family: var(--font_1);
}

input::placeholder {
  color: #14344A !important;
  opacity: 20% !important;
}

/* BANNER SLIDER */
.rcs_hero_img h1 {
  font-family: var(--primary-base);
  font-size: 50px;
  text-transform: uppercase;
  line-height: 46px;
  color: #fff;
}

.rcs_hero_img p {
  font-size: 18px;
  padding: 10px 0 20px;
  margin: 0;
  color: #fff;
}

.rcs_hero_img .outline-base-btn {
  background-color: transparent;
  border: 1px solid var(--white);
}

.outline-base-btn {
  font-size: 16px;
  border-radius: 0;
  font-weight: 800;
  box-shadow: none !important;
  outline: none !important;
  display: inline-block;
  font-family: var(--font_1);
}

.rcs_hero_img .outline-base-btn:hover,
.rcs_hero_img .outline-base-btn:active,
.rcs_hero_img .outline-base-btn:focus {
  background-color: var(--white);
  color: var(--primary);
  border-color: var(--white);
}

.outline-base-btn.large {
  padding: 12px 20px;
}

.outline-base-btn.small {
  padding: 5px 15px;
}

/* .rcs_hero_slider {
  min-height: 800px;
  display: grid;
  align-items: center;
  background-size: cover;
  background-position: right top;
} */

.heading-wrapper {
  padding-bottom: 35px;
}

.heading-wrapper span {
  font-size: 24px;
  line-height: 26px;
  font-family: var(--primary-base);
  padding-bottom: 5px;
  display: block;
  color: var(--primary);
}

.heading-wrapper h2 {
  font-family: var(--primary-medium);
  color: var(--primary);
  font-size: 40px;
  line-height: 36px;
  margin-bottom: 15px;
}

.heading-wrapper p {
  font-size: 14px;
  line-height: 22px;
  color: var(--black);
  margin: 0;
  font-weight: 400;
}

.section-space {
  padding: 100px 0;
}

.showcase-container {
  padding: 0 100px;
}

.diamonds-collection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  row-gap: 15px;
  position: relative;
}

.diamonds-collection::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
  width: 10px;
  height: 100%;
  background: url("./img/divider.png") no-repeat center/cover;
}

.diamonds-collection .diamond-item {
  text-align: center;
  cursor: pointer;
}

.diamonds-collection .diamond-item img {
  width: 120px;
}

.diamonds-collection .diamond-item p {
  font-size: 20px;
  line-height: 24px;
  font-family: var(--font_1);
  padding-top: 10px;
}

.diamonds-item-brief {
  width: 80%;
  margin: 0 auto;
}

.diamonds-item-brief .content h2 {
  font-family: var(--primary-medium);
  font-size: 30px;
  line-height: 28px;
  letter-spacing: -1px;
}

.diamonds-item-brief .content p {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 20px;
}

.diamonds-item-brief img {
  max-width: 280px;
  height: 280px;
  object-fit: contain;
  margin-right: 20px;
}

.diamonds-item-brief .content .outline-base-btn {
  border: 1px solid var(--primary);
  color: var(--primary);
}

.diamonds-item-brief .content .outline-base-btn:hover {
  color: var(--white);
  background-color: var(--primary);
}

.shop-diamond-by-shape {
  position: relative;
}

.shop-diamond-by-shape::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 10px;
  background: url("./img/divider-2.png ") no-repeat center/cover;
}

.explore-collection .item img {
  max-width: 100%;
  transition: all .5s ease;
}

.explore-collection .item:hover img {
  transform: scale(1.1);
}

.explore-collection .item a {
  font-size: 24px;
  line-height: 36px;
  color: var(--black);
  padding-top: 15px;
  display: block;
}

.explore-collection .item:hover a {
  font-weight: 800;
}

.explore-collection .item .img-box {
  overflow: hidden;
  padding-top: 0px;
}

.design-diamond-ring-section {
  background-color: var(--primary);
}

.design-diamond-ring-section .heading-wrapper * {
  color: var(--white);
}

.design-diamond-ring-section .heading-wrapper p {
  width: 60%;
}

.design-diamond-ring-section .heading-wrapper h2 {
  font-size: 50px;
  text-transform: uppercase;
  line-height: 1;
}

.design-diamond-ring-section .heading-wrapper span {
  text-transform: uppercase;
}

.design-diamond-ring-section .heading-wrapper span {
  font-weight: 400;
}

.design-diamond-ring-section .content span {
  display: inline-block;
  opacity: 0.15;
  font-size: 50px;
  line-height: 60px;
  font-family: var(--primary-bold);
  padding-bottom: 15px;
}

.design-diamond-ring-section .content * {
  color: var(--white);
}

.design-diamond-ring-section .content h3 {
  font-family: var(--primary-base);
  font-size: 26px;
  line-height: 25px;
  padding-bottom: 15px;
  margin: 0;
}

.design-diamond-ring-section .content p {
  padding-bottom: 25px;
  font-size: 14px;
  margin: 0;
  width: 80%;
}

.design-diamond-ring-section .content .outline-base-btn {
  border: 1px solid var(--white);
  font-weight: 400;
  text-transform: capitalize;
  font-size: 14px;
  font-family: var(--font_1);
}

.design-diamond-ring-section .content .outline-base-btn:hover {
  background: #fff;
  color: #000;
}

.design-diamond-ring-section,
.design-diamond-ring-section .video-item {
  position: relative;
}

.design-diamond-ring-section::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: url("./img/shape.png") no-repeat center/cover;
  width: 200px;
  height: 200px;
}

.design-diamond-ring-section .video-item video {
  width: 100%;
}

.design-diamond-ring-section .video-item .content {
  position: absolute;
  top: 0;
  left: 50px;
}

.shop-ring-by-style .item a {
  display: block;
  font-family: var(--font_1);
  color: var(--black);
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  padding-top: 15px;
}

.shop-ring-by-style .item:hover a {
  font-weight: 700;
}

.shop-ring-by-style {
  position: relative;
}

.shop-ring-by-style .slick-slider .slick-arrow {
  position: absolute;
  top: -70px;
  right: 0;
  left: auto;
  width: 30px;
  height: auto;
}

.shop-ring-by-style .slick-slider .slick-arrow.slick-prev {
  right: 40px;
}

.gemstone-glamour .heading-wrapper h2,
.gemstone-glamour .heading-wrapper span {
  text-transform: uppercase;
}

.gemstone-glamour .heading-wrapper p {
  width: 50%;
  margin: 0 auto;
}

.gemstone-glamour .item {
  text-align: center;
}

.gemstone-glamour .item p {
  font-size: 12px;
  color: var(--white);
  padding-top: 10px;
}

.gemstone-glamour {
  background: url("./img/gemstone-bg.jpg") no-repeat center/cover;
}

.gemstone-glamour .heading-wrapper * {
  color: var(--white);
}

.gemstone-glamour .outline-base-btn {
  color: var(--white);
  border: 1px solid var(--white);
}

.gemstone-glamour .outline-base-btn:hover {
  background: #fff;
  color: var(--primary);
  border: 1px solid var(--primary);
}

.custom-design .heading-wrapper {
  width: 65%;
  margin: 0 auto;
}

.custom-design .outline-base-btn {
  border: 1px solid var(--primary);
  text-transform: uppercase;
}

.custom-design .outline-base-btn:hover {
  background: var(--primary);
  color: var(--white);
}

.consult-specialist-section div[class*="col-"]:first-child {
  background-color: var(--primary);
}

.consult-specialist-section .inner-wrap {
  width: 70%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  place-content: center;
  justify-content: center;
}

.consult-specialist-section .inner-wrap * {
  color: var(--white);
}

.consult-specialist-section .inner-wrap span {
  font-size: 24px;
  line-height: 30px;
  font-family: var(--primary-base);
  text-transform: uppercase;
}

.consult-specialist-section .inner-wrap h2 {
  font-family: var(--primary-medium);
  text-transform: uppercase;
  font-size: 50px;
  line-height: normal;
}

.consult-specialist-section .inner-wrap p {
  font-size: 16px;
  line-height: 20px;
}

.consult-specialist-section .inner-wrap a {
  border: 1px solid var(--white);
  width: fit-content;
  margin: 0 auto;
}

.consult-specialist-section .inner-wrap a:hover {
  background: #fff;
  color: var(--primary);
}

.promises-section {
  position: relative;
}

.promises-section::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 10px;
  background: url("./img/divider-2.png ") no-repeat center/cover;
}

.promises-section .item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.promises-section .row div[class*="col-"] {
  position: relative;
}

.promises-section .row div[class*="col-"]:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  width: 2px;
  height: calc(100% + 40px);
  background-color: var(--primary);
  opacity: 0.3;
}

.promises-section .row.align-items-center>div[class*="col-"]:first-child:before,
.promises-section .row div[class*="col-"]:last-child:before {
  display: none;
}

.promises-section .item p {
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 5%;
  font-family: var(--font_2);
  margin-left: 15px;
}

.rcs_customer_say_section .outline-base-btn {
  background: transparent;
  border: 1px solid var(--primary);
  color: var(--primary);
}

.rcs_customer_say_section .outline-base-btn:hover {
  background: var(--primary);
  color: var(--white);
}

.rcs_customer_say_section {
  background-color: #F5F8F9;
}

.rcs_customer_say_section .rcs_customer_slide_text_content p {
  font-size: 12px;
  line-height: 1.8;
  color: var(--black);
  letter-spacing: 0.4px;
  margin-bottom: 5px;
}

.rcs_customer_say_section .rcs_customer_slide_text_content a {
  color: var(--black);
  font-family: var(--font_2);
  text-decoration: underline;
}

.rcs_customer_say_section .rcs_customer_review_slide_title h3 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.rcs_customer_say_section .MuiRating-sizeMedium {
  font-size: 1rem;
}

.rcs_customer_say_section .name-charAt {
  font-size: 20px;
  font-family: var(--font_1);
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: #0097A7;
  text-align: center;
  line-height: 35px;
  color: var(--white);
  position: relative;
  z-index: 1;
  text-transform: uppercase;
}

.rcs_customer_say_section .name-charAt::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45px;
  height: 45px;
  background-color: #0097A7;
  opacity: 0.1;
  border-radius: 100%;
  z-index: -1;
}

.rcs_customer_say_section {
  padding: 50px 0;
}

.rcs_customer_say_section .slick-slide {
  padding: 10px;
}

.rcs_customer_say_section .rcs_customer_review_slide_item {
  padding: 15px 20px;
  border: 1px solid #DDE0E1;
}

.insta-iframe {
  height: 400px;
}

.rcs_signup_to_input_field input {
  height: 50px;
  border-radius: 0;
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid rgb(20, 52, 74, 0.1);
  background: #FFF;
}

.rcs_signup_to_input_field input::placeholder {
  color: #14344A;
  opacity: 1;
}

.rcs_signup_to_input_field input:active,
.rcs_signup_to_input_field input:focus {
  border: 1px solid rgb(20, 52, 74, 1);
}


.rcs_signup_to_input_field button {
  background: var(--primary) !important;
  color: #FFF;
  text-transform: uppercase;
  font-family: var(--font_1);
  letter-spacing: 1px;
  border: none;
}

.rcs_signup_to_section {
  background: url("./img/newsletter-bg.jpg") no-repeat center/cover;
}



@media (min-width:1280px) and (max-width:1439px) {
  .showcase-container {
    padding: 0 30px;
  }

  .rcs_hero_slider {
    min-height: 600px;
  }

  .diamonds-collection {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  .diamonds-item-brief img {
    max-width: 250px;
    height: 250px;
  }

  .diamonds-item-brief {
    width: 100%;
  }

  .outline-base-btn {
    font-size: 14px;
  }

  section.shop-ring-by-style.section-space .slick-slide img {
    width: 97%;
  }

  .gemstone-glamour .item img {
    width: 85%;
  }

  .consult-specialist-section img {
    width: 100%;
  }

  .design-diamond-ring-section .heading-wrapper h2 {
    font-size: 45px;
  }

  section.design-diamond-ring-section .content.pl-3.pr-5 {
    padding-right: 0px !important;
  }

  .section-space {
    padding: 60px 0;
  }

  .design-diamond-ring-section {
    padding-bottom: 70px !important;
  }

  .design-diamond-ring-section .video-item video {
    /* position: relative; */
    /* top: 120px; */
  }

  .design-diamond-ring-section .video-item .content {
    padding-right: 100px;
  }

  .design-diamond-ring-section .content p {
    font-size: 13px;
    width: 100%;
  }

  .design-diamond-ring-section .content .outline-base-btn {
    font-size: 12px;
  }

  .heading-wrapper h2 {
    font-size: 35px;
  }

  .heading-wrapper p {
    font-size: 13px;
  }

  .custom-design .heading-wrapper {
    width: 79%;
  }

  .consult-specialist-section .inner-wrap h2 {
    font-size: 40px;
  }

  .consult-specialist-section .showcase-container>div {
    width: 100%;
    overflow: hidden;
  }

  .rcs_customer_say_section .rcs_customer_slide_text_content p {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.2px;
    height: 130px;
  }

  .promises-section .item p {
    font-size: 16px;
  }

  .promises-section .heading-wrapper h2 {
    font-size: 28px;
  }

  .promises-section .row div[class*="col-"]:before {
    width: 1px;
    height: calc(100% + 20px);
  }

  .insta-iframe {
    height: 300px;
  }

  .rcs_footer_content_one .logo img {
    /* width: 90%; */
  }
}

@media (max-width:768px) {
  img {
    width: 100%;
  }
}

@media (max-width:991px) {
  .wistia_responsive_padding {
    margin-bottom: 30px;
  }

  .RingBuilderHome .content {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .design-diamond-ring-section .content .outline-base-btn {
    font-size: 13px;
  }

  .diamonds-collection .diamond-item img {
    width: 100%;
  }

  .section-space {
    padding: 50px 0;
  }

  .showcase-container {
    padding: 0 10px;
  }

  .rcs_hero_img h1 {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0;
  }

  .rcs_hero_img p {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding-top: 0;
  }

  .rcs_hero_img button {
    font-size: 14px;
    font-weight: 500;
    text-align: center;

  }

  section.explore-collection.section-space .row {
    margin: 0;
  }

  .rcs_hero_slider {
    min-height: 468px;
    background-position: center;
    padding-bottom: 60px;
    align-items: end;
  }

  .heading-wrapper h2 {
    font-size: 30px;
  }

  .heading-wrapper p {
    font-size: 12px;
  }

  section.shop-diamond-by-shape.section-space .inner-shop-diamond-shape {
    padding: 0px 70px;
  }

  .diamonds-collection::after,
  .shop-diamond-by-shape::after {
    display: none;
  }

  .diamonds-collection .diamond-item .name {
    font-family: 'Optima';
    font-size: 22px;
    font-weight: 550;
    color: var(--primary);
    margin: 0;
  }

  .diamonds-collection .diamond-item .desc {
    font-family: 'Gotham';
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    margin: 0;
  }

  .diamonds-collection .diamond-item a {
    font-family: 'Gotham';
    font-size: 12px;
    font-weight: 500;
    color: var(--primary);
    border: 1px solid var(--primary);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .design-diamond-ring-section .video-item {
    margin-bottom: 20px;
  }

  /* .slick-dots li button:before {
    width: 10px !important;
    content: ' ' !important;
    background: #000 !important;
    height: 1px !important;
  } */

  .slick-dots li {
    margin: 0 !important;
  }

  .shop-diamond-by-shape .heading-wrapper {
    padding-bottom: 0;
  }

  .explore-collection .item a {
    font-size: 15px;
    font-weight: 400;
    padding: 0;
    margin-bottom: 15px;
  }

  .heading-wrapper {
    width: 74%;
    margin: auto;
    text-align: center !important;
  }

  /* design-diamond-ring-section */

  .design-diamond-ring-section .heading-wrapper {
    text-align: center;
    width: 100%;
  }

  .design-diamond-ring-section .heading-wrapper p {
    width: 100%;
  }

  .design-diamond-ring-section .heading-wrapper span {
    font-size: 20px;
    line-height: inherit;
  }

  .design-diamond-ring-section .heading-wrapper h2 {
    font-size: 30px;
    font-weight: 550;
    line-height: 28px;
  }

  .mobile-slider-ringsetting-home .item {
    text-align: center;

  }

  .mobile-slider-ringsetting-home .item img {
    width: 50%;
    margin: 20px auto 30px;
  }

  .mobile-slider-ringsetting-home .item p {
    margin: auto;
  }

  .mobile-slider-ringsetting-home .item .content {
    padding: 0 !important;
  }

  .mobile-slider-ringsetting-home .slick-dots {
    position: absolute;
    top: 35px;
    bottom: auto;
    display: flex !important;
  }

  .mobile-slider-ringsetting-home .slick-dots li {
    width: 33%;
    height: 100%;
    border-right: 2px solid #ffffff51;
  }

  .mobile-slider-ringsetting-home .slick-dots li:last-child {
    border-right: 0;
  }

  .mobile-slider-ringsetting-home .slick-dots li a {
    font-family: 'Optima';
    font-size: 20px;
    font-weight: 600;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin: auto;
    background: #ffffff41;
    color: #ffffff97;
  }

  .mobile-slider-ringsetting-home .slick-dots li.slick-active a {
    background: #fff;
    color: var(--primary);
  }

  .mobile-slider-ringsetting-home .slick-list {
    padding-top: 80px;
  }

  .design-diamond-ring-section {
    padding-bottom: 50px !important;
  }

  section.RingBuilderHome.design-diamond-ring-section.section-space .align-items-center.row {
    flex-direction: column-reverse;
  }

  .design-diamond-ring-section .video-item .content {
    position: static;
  }

  .design-diamond-ring-section .video-item video {
    width: 100%;
    /* position: relative; */
    /* top: -85px; */
    /* margin-bottom: -90px; */
  }

  section.shop-ring-by-style.section-space .slick-slide img {
    padding: 5px;
    width: 100%;
  }

  section.shop-ring-by-style.section-space .slick-dots {
    bottom: -45px;
  }

  .gemstone-glamour .heading-wrapper p {
    width: 100%;
  }

  .gemstone-glamour .heading-wrapper {
    width: 100%;
  }

  .heading-wrapper span {
    font-size: 20px;
  }

  .gemstone-glamour {
    background-position: left;
  }

  .gemstone-glamour .gemstone-wrapper {
    flex-wrap: wrap;
  }

  .custom-design .heading-wrapper {
    width: 78%;
    padding: 20px 0;
  }

  .consult-specialist-section .row {
    flex-direction: column-reverse;
  }

  .consult-specialist-section {
    padding: 20px;
  }

  .consult-specialist-section .inner-wrap h2 {
    font-size: 30px;
    font-weight: 550;
    padding: 10px 0;
  }

  .consult-specialist-section .inner-wrap span {
    font-size: 20px;
    font-weight: 400;
  }

  .consult-specialist-section .inner-wrap p {
    font-size: 12px;
    font-weight: 400;
  }

  .consult-specialist-section .inner-wrap {
    width: 100%;
    padding: 50px 0;
  }

  .rcs_customer_say_section .rcs_customer_slide_text_content a {
    font-size: 12px;
  }

  .insta-iframe {
    height: 210px;
  }

  .rcs_signup_to_input_field input,
  .rcs_signup_to_input_field button {
    min-width: 100% !important;
    /* margin-top: 10px; */
  }

  /* form.gs_news_form.w-100 .input-group {
    display: block;
  } */

  .rcs_signup_to_section {
    background: url('../Assets/img/mb-bg-news.png');
  }

  .rcs_logo_head.rcs_sticky-top.sticky-top .rcs_search_bar.mobileSearchBox {
    display: none;
  }

  .rcs_signup_to_input_field {
    margin-top: 15px;
  }
}

@media (min-width:1440px) and (max-width:1900px) {
  .rcs_hero_slider {
    min-height: 635px;
  }
  
  section.explore-collection.section-space .col-md-3.col-6 {
    padding: 0 8px;
  }

  .rcs_customer_say_section .rcs_customer_slide_text_content p {
    height: 130px;
  }
  .rcs_customer_say_section .rcs_customer_review_slide_title h3 {
    font-size: 13px;
  }
  .consult-specialist-section img {
    width: 100%;
  }

  section.shop-ring-by-style .slick-slide img {
    width: 100%;
    padding: 10px;
  }

  .promises-section .item p {
    font-size: 17px;
  }

  .heading-wrapper h2 {
    font-size: 30px;
  }

  .design-diamond-ring-section .video-item video {
    margin-top: 55px;
  }

  section.design-diamond-ring-section.section-space.pb-0 {
    padding-bottom: 100px !important;
  }

  .diamonds-collection {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }

  .diamonds-item-brief {
    width: 100%;
  }

  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img {
    right: 42px !important;
  }

  .rcs_bg-slide li {
    font-size: 13px !important;
    padding-bottom: 4px !important;
  }

  .appointment-report-btn .rcs_border_btn {
    font-size: 14px !important;
  }

  .ShowcaseRingseting_ProdFilter li svg {
    width: 70px !important;
    height: 70px !important;
  }

  .ShowcaseRingseting_ProdFilter {
    flex-wrap: wrap !important;
  }

  .consult-specialist-section .inner-wrap h2 {
    font-size: 36px;
  }

  .insta-iframe {
    height: 290px;
  }
}


@media (min-width:769px) and (max-width:991px) {}

@media (min-width:991px) and (max-width:1279px) {
  .diamonds-collection .diamond-item img {
    margin: auto;
  }

  .rcs_hero_img h1 {
    font-size: 40px;
  }

  .consult-specialist-section img {
    width: 100%;
  }

  .showcase-container {
    padding: 0 10px;
    overflow: hidden;
  }

  .diamonds-collection {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  .diamonds-item-brief img {
    max-width: 200px;
    height: 200px;
    margin-right: 0px;
  }

  .diamonds-item-brief {
    width: 100%;
  }

  .outline-base-btn {
    font-size: 12px;
  }

  section.shop-ring-by-style.section-space .slick-slide img {
    width: 100%;
    padding: 4px;
  }

  .gemstone-wrapper {
    flex-wrap: wrap;
  }

  .gemstone-glamour .item {
    text-align: center;
    padding: 0 20px;
  }

  .heading-wrapper h2 {
    font-size: 30px;
  }

  .design-diamond-ring-section .heading-wrapper h2 {
    font-size: 40px;
  }

  .section-space {
    overflow-x: hidden;
  }

  section.rcs_customer_say_section .slick-next {
    right: 0;
  }

  section.rcs_customer_say_section .slick-prev {
    left: 0;
  }

  .consult-specialist-section .inner-wrap {
    padding: 30px 0;
    width: 100%;
  }

  .section-space {
    padding: 40px 0;
  }

  .gemstone-glamour .heading-wrapper p {
    width: 100%;
    margin: 0 auto;
  }
}

.rcs_customer_review_slide_img img {
  width: auto;
}

@media (min-width:767px) and (max-width:1023px) {
  section {
    overflow-x: hidden;
  }

  .diamonds-collection .diamond-item img {
    /* width: auto !important; */
    margin: auto;
  }

  .diamonds-collection .diamond-item .desc {
    width: 75%;
    margin: auto !important;
  }

  .RingBuilderHome.design-diamond-ring-section .row .col-md-5.col-12,
  .RingBuilderHome.design-diamond-ring-section .row .col-md-7.col-12,
  section.gemstone-glamour .col-md-6,
  .consult-specialist-section .col-md-6,
  .custom-design .col-md-6,
  #rcs_footer_bottom .col-lg-4.col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .custom-design .col-md-6 img {
    width: auto;
  }

  .insta-iframe {
    height: 175px;
  }

  .consult-specialist-section img {
    width: 100%;
  }

  .gemstone-glamour {
    background-size: cover;
    background-position: right;
  }
}

@media (min-width:540px) and (max-width:991px) {
  .gemstone-glamour .gemstone-wrapper {
    flex-wrap: nowrap;
  }

  .gemstone-glamour .item img {
    width: 70%;
  }

  .gemstone-glamour .item p {
    font-size: 10px;
  }

  .gemstone-glamour {
    background-image: linear-gradient(45deg, #2a3340, #1e252e);
  }
}

@media only screen and (max-width: 767px) {
  .rcs_filter_accordion_sec.rcs_filter_accordion_sec_diamond > div {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}
